@import '../App.sass';

.testimonial {
    background: $white;
    padding: 2rem;
    .quote {
        h3 {
            font-size: 1rem;
            margin: 0;
            margin-bottom: 2rem; } }
    .details {
        display: flex;
        .avatar {
            flex: none;
            width: 50px;
            margin-right: 1rem;
            img {
                width: 100%;
                border-radius: 50%; } }
        .info {
            p {
                margin: 0;
                span {
                    display: block; } }
            .name {
                letter-spacing: 0.2rem; }
            .position {
                color: rgba($black, 0.5);
                font-size: 0.8rem; } } } }
