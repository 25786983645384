@import '../App.sass';

.contact {
    background: url('../assets/contact.png'), linear-gradient($primary, $secondary);
    background-position: right bottom;
    background-repeat: no-repeat;
    color: $white;
    text-align: center;
    padding: 12rem 0;
    @media screen and ( max-width: $mobileWidth ) {
        background-position: center bottom; }
    h2 {
        font-size: 3rem;
        line-height: 3rem;
        margin: 0 0 4rem 0;
        @media screen and ( max-width: $mobileWidth ) {
            font-size: 2rem;
            line-height: 2rem; }
        .hightlight {
            color: $accent; } }
    .button {
        display: flex;
        justify-content: center; } }
