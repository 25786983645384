@import '../App.sass';

.other-skill {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($black, 0.04);
    color: rgba($black, 0.7);
    padding: 0.7rem 1rem;
    margin: 0.2rem;
    svg {
        width: 1.2rem;
        margin-right: 0.5rem; } }
