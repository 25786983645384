@import '../App.sass';

.header {
    position: fixed;
    width: 100%;
    background: $black;
    color: $white;
    padding: 1rem 0;
    z-index: 999;
    .row {
        display: flex;
        justify-content: space-between;
        > div {
            display: flex;
            align-items: center; }
        .logo {
            img {
                height: 2rem; } }
        .social {
            > a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                color: $accent;
                border-radius: 50%;
                border: 3px solid $secondary;
                margin: 0 0.5rem;
                opacity: 0.8;
                transition: all 0.4s ease-in-out;
                &:hover {
                    opacity: 1; }
                @media screen and ( max-width: $mobileWidth ) {
                    margin: 0 0.2rem; }
                svg {
                    width: 20px; } } }
        .burger {
            .icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border: 2px solid $white;
                border-radius: 50%;
                cursor: pointer;
                opacity: 0.8;
                transition: all 0.4s ease-in-out;
                &:hover {
                    opacity: 1; }
                .line {
                    width: 18px;
                    height: 2px;
                    background: $white;
                    border-radius: 50px;
                    margin: 0.1rem 0;
                    &:nth-of-type(2) {
                        width: 13px; } } } } } }
