@import '../App.sass';

.skills {
    background: linear-gradient(to right, rgba($black, 0.3), rgba($black, 0.1) 50%);
    padding: 0 0 3rem 0;
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        transform: translateY(-40px);
        @media screen and ( max-width: $mobileWidth ) {
            grid-template-columns: 1fr;
            transform: translateY(-20px); }
        .illustration {
            @media screen and ( max-width: $mobileWidth ) {
                grid-row: 2; }
            img {
                width: 100%;
                animation: bounce 2s ease-in-out infinite; } }
        .cards {
            > div {
                background: $white;
                padding: 2rem;
                box-shadow: 5px 5px 20px 5px rgba($black, 0.15);
                margin-bottom: 1rem;
                &:nth-of-type(2) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    img {
                        width: 50px;
                        margin: 0.5rem; } }
                h2 {
                    font-size: 3rem;
                    line-height: 3rem;
                    margin: 0 0 2rem 0;
                    @media screen and ( max-width: $mobileWidth ) {
                        font-size: 2rem;
                        line-height: 2rem; } }
                .other-skills {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center; }
                .button {
                    display: flex;
                    justify-content: center;
                    margin: 3rem 0 1rem 0; } } } } }
