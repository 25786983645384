@import '../App.sass';

.hero {
    min-height: 100vh;
    background: linear-gradient($black, $black-light);
    background: -webkit-linear-gradient($black, $black-light);
    background: -moz-linear-gradient($black, $black-light);
    color: $white;
    overflow-x: hidden;
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        min-height: 100vh;
        @media screen and ( max-width: $mobileWidth ) {
            grid-template-columns: 1fr;
            padding: 6rem 0 3rem 0; }
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center; }
        .illustration {
            img {
                width: 100%;
                animation: lighter 2s ease-in-out infinite; } } }
    h1 {
        background: linear-gradient($secondary, $accent);
        background: -webkit-linear-gradient($secondary, $accent);
        background: -moz-linear-gradient($secondary, $accent);
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3rem;
        line-height: 3.2rem;
        margin-bottom: 1rem; }
    p {
        font-size: 1rem;
        line-height: 1.8rem;
        margin-bottom: 3rem;
        @media screen and ( max-width: $mobileWidth ) {
            font-size: inherit;
            line-height: inherit; } } }
