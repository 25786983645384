@import '../App.sass';

.main-button {
    position: inline-block;
    display: flex;
    align-items: center;
    width: fit-content;
    background: $accent;
    color: $primary;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    border-radius: 50px;
    padding: 1rem 2rem;
    transition: all 0.4s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 20px 0px rgba($accent, 0.8); }
    svg {
        margin-left: 0.5rem; } }
