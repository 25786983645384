@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&family=Roboto+Slab:wght@400;700&display=swap');

$black: rgb(51, 51, 51);
$black-light: rgb(70, 70, 70);
$white: rgb(255, 255, 255);
$primary: rgb(88, 61, 114);
$secondary: rgb(159, 95, 128);
$accent: rgb(255, 201, 150);

$mobileWidth: 767px;
$tabletWidth: 1080px;

body {
    font-family: 'Poppins', sans-serif;
    color: $black;
    margin: 0;
    overflow-x: hidden; }

a {
    text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Slab', serif; }

.container {
    max-width: 1080px;
    padding: 0 1rem;
    margin: 0 auto;
    box-sizing: border-box; }

@keyframes bounce {
	0% {
		transform: scale(1); }
	50% {
		transform: scale(1.02) rotate(2deg); }
	100% {
		transform: scale(1); } }

@keyframes lighter {
	0% {
		opacity: 0.5 {
        transform: scale(0.7); } }
	50% {
		opacity: 1 {
        transform: scale(1); } }
	100% {
		opacity: 0.5 {
        transform: scale(0.7); } } }
