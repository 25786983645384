@import '../App.sass';

.testimonials {
    background: rgba($primary, 0.08);
    padding: 0 0 3rem 0;
    .title {
        background: linear-gradient(to right, $primary, $secondary);
        color: $white;
        text-align: center;
        padding: 2rem;
        margin-bottom: 1rem;
        h2 {
            font-size: 3rem;
            line-height: 3rem;
            margin: 0;
            @media screen and ( max-width: $mobileWidth ) {
                font-size: 2rem;
                line-height: 2rem; } }
        p {
            margin: 0.5rem 0 0 0; } }
    .row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 1rem; }
    .button {
        display: flex;
        justify-content: center;
        padding-top: 2rem; } }
