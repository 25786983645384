@import '../App.sass';

.carousel-cell {
    width: 800px;
    height: 500px;
    @media screen and ( max-width: $mobileWidth ) {
        width: 100%; }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 2rem 2rem 2rem 100px;
        box-sizing: border-box;
        @media screen and ( max-width: $mobileWidth ) {
            padding: 2rem 2rem 2rem 20px; }
        .title {
            font-size: 3rem;
            line-height: 3rem;
            @media screen and ( max-width: $mobileWidth ) {
                font-size: 2rem;
                line-height: 2rem; } }
        .subtitle {
            font-size: 1.6rem;
            @media screen and ( max-width: $mobileWidth ) {
                font-size: 1.2rem; } }
        .details {
            font-size: 1rem;
            font-weight: 300; }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background: $white;
            color: $black;
            border-radius: 50%;
            margin-top: 1rem; } } }
