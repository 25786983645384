@import '../App.sass';

.footer {
    background: $black;
    color: rgba($white, 0.7);
    font-size: 0.8rem;
    padding: 6rem 0;
    box-sizing: border-box;
    overflow-x: hidden;
    @media screen and ( max-width: $mobileWidth ) {
        padding: 3rem 0; }
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and ( max-width: $mobileWidth ) {
            grid-template-columns: 1fr; }
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 2rem;
            @media screen and ( max-width: $mobileWidth ) {
                padding: 2rem 0; }
            &:nth-of-type(1) {
                align-items: flex-end;
                @media screen and ( max-width: $mobileWidth ) {
                    grid-row: 2;
                    align-items: center; }
                img {
                    max-width: 100%;
                    animation: lighter 2s ease-in-out infinite; } }
            &:nth-of-type(2) {
                border-left: 2px solid rgba($white, 0.3);
                @media screen and ( max-width: $mobileWidth ) {
                    border-left: none;
                    border-bottom: 2px solid rgba($white, 0.3); } } }
        p {
            line-height: 1.6rem; }
        a {
            color: $white;
            transition: all 0.4s ease-in-out;
            &:hover {
                color: $accent; } } } }
